import styled from 'styled-components/macro';
import {
  Button,
  PopoverHeader,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';

export const PopupButton = styled(Button)`
  padding: 1% !important;
  width: 15px !important;
  height: 15px !important;
  font-size: 0.7rem !important;
  line-height: 0.5 !important;
  /* display: flex; */
  margin: 0 0 4px 8px !important;
  border-radius: 21px !important;
  background-color: #000 !important;
  border-color: #000 !important;
`;

export function Popup(props) {
  const { header, children, id } = props;
  return (
    <>
      <PopupButton tabIndex="-1" id={`popup-${id}`} type="button">
        ?
      </PopupButton>
      <UncontrolledPopover
        trigger="legacy"
        placement="right"
        target={`popup-${id}`}
      >
        <PopoverHeader style={{ fontSize: '0.78rem' }}>{header}</PopoverHeader>
        <PopoverBody style={{ fontSize: '0.78rem' }}>{children}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
