import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import ErrorCustomMessage from '../components/ErrorPage/error-custom-message';
import Public from './public';

import LoadingScreen from '../components/loader';

const getApiBaseUrl = () => {
  const env = process.env.REACT_APP_ENV;
  const productionEnvs = ['production', 'staging', 'develop', 'uat'];
  return productionEnvs.includes(env) ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
};

// Pattern Matching: Validate against a known regex pattern
const tokenRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const isValidToken = (token) => tokenRegex.test(token);

// Encoding: Encode special characters
const encodeToken = (token) => encodeURIComponent(token);

// Function to validate and sanitize the simulatorToken
const sanitizeToken = (token) => {    
  if (!isValidToken(token)) {
    throw new Error('Invalid token format');
  }
  return encodeToken(token);
};

function PublicPage({ simulatorToken, version }) {
  const sanitizedToken = sanitizeToken(simulatorToken);// Sanitize and validate the token
  const [hasError, setHasError] = useState('');
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Ensure the API request is made over HTTPS
  const api = axios.create({
    baseURL: `${getApiBaseUrl()}`, //  Make sure your environment variables use HTTPS URLs
    responseType: 'json',
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });

  const fetchSimulationData = useCallback(async () => {
    try {
      const response = await api.get(`/api/simulation/${sanitizedToken}`);
      setPageData(response.data);
      setIsLoading(false);
    } catch (error) {
      setHasError('This is not the web page you are looking for!');
      setIsLoading(false);
    }
  }, [sanitizedToken]);

  useEffect(() => {
    // Implement rate limiting logic here
    fetchSimulationData();
  }, [fetchSimulationData]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    hasError ? (
      <ErrorCustomMessage message={hasError} version={version} />
    ) : (
      <Public linkData={pageData} version={version} />
    )
  );
}

export default PublicPage;
