import { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Formik, Form, Field, ErrorMessage } from 'formik';

import axios from 'axios';
import { Transition } from '@headlessui/react';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';

import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col, Label, Spinner } from 'reactstrap';
import { sum } from 'lodash';
import './style.scss';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  PinterestIcon,
  PinterestShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { Popup } from '../../components/general/popup';

import Logo from './images/logo.png';
import Yellow_Triangle from './images/arrow.png';
import VideoOne from './video-one';
import VideoImageOne from './images/Simulator-Video-Image-HD.jpg';
import VideoTwo from './video-two';
import VideoImageTwo from './images/Simulator-Video-Image-Two.png';
import DirectionArrows from './images/directional_arrow.svg';
import GraphOne from './graph-one';
import GraphTwo from './graph-two';
import TableTwo from './tableTwo';
import Valuation from './valuation';
import { getCurrencies } from './get-currencies';
import { CalcInputContainer, SimSelect } from './styles';


// Yup validation schema
const validationSchema = Yup.object().shape({
  annualRevenue: Yup.number().min(1, 'Annual Revenue must be greater than 0').required('Annual Revenue is required'),
  grossProfitMargin: Yup.number()
    .min(0, 'Gross Profit Margin must be at least 0')
    .max(100, 'Gross Profit Margin cannot be greater than 100')
    .required('Gross Profit Margin is required'),
  netProfitMargin: Yup.number()
    .min(0, 'Must be at least 0')
    .max(Yup.ref('grossProfitMargin'), 'Net Profit Margin cannot be greater than Gross Profit Margin')
    .required('Net Profit Margin is required'),
  // currency: Yup.string().required('Currency is required'),
});

const userValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const url = (window.location !== window.parent.location) ? document.referrer : document.location.href;
const share_url = `${url}`;

// reCaptcha site key
// const reCaptcha_site_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY_VALUE;
// const reCaptcha_site_secret = process.env.REACT_APP_RECAPTCHA_SITE_SECRET;

// Capture the current page URL
const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
const targetPath = '/simulator-next-steps';
const FULLPATH = baseUrl + targetPath;

function Public({ linkData }) {
  const [toggle, toggleDeepDive] = useState(false);
  // const [form, setForm] = useState(true);

  // const [recaptchaTokenErrors, setRecaptchaTokenErrors] = useState('');
  const [sliderStyle, setSliderStyle] = useState(0);
  const newSectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [isHiddenSectionVisible, setHiddenSectionVisible] = useState(false);
  // const [isPopupVisible, setPopupVisible] = useState(false);
  const [isAlternateLogo, setAlternateLogo] = useState(false);

  const toggleHiddenSection = () => {
    setHiddenSectionVisible(!isHiddenSectionVisible);
  };

  const checkForErrors = (errors) => {
    setHasError(Object.keys(errors).length > 0);
  };

  // const togglePopup = () => {
  //   setPopupVisible(!isPopupVisible);
  // };

  // const toggleLogo = () => {
  //   setAlternateLogo(!isAlternateLogo);
  // };

  const scrollToNewSection = () => {
    if (newSectionRef.current) {
      newSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAutoScroll = () => {
    toggleDeepDive(!toggle); // Toggle functionality
    toggleHiddenSection();
    scrollToNewSection(); // Scroll functionality
  };

  useEffect(() => {
    if (toggle) { // Assuming 'toggle' is the state that controls the visibility of the section
      scrollToNewSection();
    }
  }, [toggle]); // Runs only when 'toggle' changes


  const video_1 = 'https://karlbryan.s3.amazonaws.com/simulator/Sim-Video-1.mp4';
  const video_2 = 'https://karlbryan.s3.amazonaws.com/simulator/Sim-Video-2.mp4';

  const [annualRevenueValue, setAnnualRevenueValue] = useState(0);
  const [grossProfitMarginValue, setGrossProfitMarginValue] = useState(0);
  const [netProfitMarginValue, setNetProfitMarginValue] = useState(0);
  const [impactValue, setImpactValue] = useState(0);
  const [modal, setModal] = useState(false);
  // const [firstNameValue, setFirstNameValue] = useState('');
  // const [lastNameValue, setLastNameValue] = useState('');
  // const [emailValue, setEmailValue] = useState('');
  const [currencyValue, setCurrencyValue] = useState('$');

  // value impact that will change when table 
  // toggle happens
  const [impact_value_cut_cost, set_impact_value_cut_cost] = useState(impactValue);
  const [impact_value_market_dominating_position, set_impact_value_market_dominating_position] = useState(impactValue);
  const [impact_value_compelling_offer, set_impact_value_compelling_offer] = useState(impactValue);
  const [impact_value_increase_prices, set_impact_value_increase_prices] = useState(impactValue);
  const [impact_value_upsell_cross_sell, set_impact_value_upsell_cross_sell] = useState(impactValue);
  const [impact_value_bundling, set_impact_value_bundling] = useState(impactValue);
  const [impact_value_downsell, set_impact_value_downsell] = useState(impactValue);
  const [impact_value_additional_products_services, set_impact_value_additional_products_services] = useState(impactValue);
  const [impact_value_drip_campaign, set_impact_value_drip_campaign] = useState(impactValue);
  const [impact_value_alliances_joint_ventures, set_impact_value_alliances_joint_ventures] = useState(impactValue);
  const [impact_value_more_leads, set_impact_value_more_leads] = useState(impactValue);
  const [impact_value_digital_marketing, set_impact_value_digital_marketing] = useState(impactValue);
  // const recaptchaRef = useRef(null);
  // const [recaptchaValue, setRecaptchaValue] = useState('');


  const modalToggle = () => setModal(!modal);

  const modalToggleHyper = () => {
    if (toggle === true) {
      toggleDeepDive(true);
    } else {
      toggleDeepDive(!toggle);
    }
    setModal(!modal);
  };
  // currencies
  const currencies = getCurrencies.map((e) => ({ label: `${e.name} - ${e.code}`, value: e.code }));

  const onSelectChange = ({ value }) => {
    setCurrencyValue(value);
  };

  const updateAnnualRevenue = (value) => {
    let val = value;
    if (val < 0) {
      val = 0;
    }
    setAnnualRevenueValue(val);

  };

  const updateGrossProfitMargin = (value) => {
    let val = value;
    if (val > 100) {
      val = 100;
    }
    if (val < 0) {
      val = 0;
    }
    setGrossProfitMarginValue(val);

  };

  const updateValue = (value, index, id) => {
    let val = value;
    val = (value === undefined) ? 0 : value;
    if (val > 10) {
      val = 10;
    }
    if (id === 1) {
      set_impact_value_cut_cost(parseFloat(val));
    }
    if (id === 2) {
      set_impact_value_market_dominating_position(parseFloat(val));
    }
    if (id === 3) {
      set_impact_value_compelling_offer(parseFloat(val));
    }
    if (id === 4) {
      set_impact_value_increase_prices(parseFloat(val));
    }
    if (id === 5) {
      set_impact_value_upsell_cross_sell(parseFloat(val));
    }
    if (id === 6) {
      set_impact_value_bundling(parseFloat(val));
    }
    if (id === 7) {
      set_impact_value_downsell(parseFloat(val));
    }
    if (id === 8) {
      set_impact_value_additional_products_services(parseFloat(val));
    }
    if (id === 9) {
      set_impact_value_drip_campaign(parseFloat(val));
    }
    if (id === 10) {
      set_impact_value_alliances_joint_ventures(parseFloat(val));
    }
    if (id === 11) {
      set_impact_value_more_leads(parseFloat(val));
    }
    if (id === 12) {
      set_impact_value_digital_marketing(parseFloat(val));
    }

  };

  // setcurrencyValue(currencyValue.code);
  const checkNetProfitValue = (value) => {
    let val = value.floatValue;
    if (val < 0) {
      val = 0;
      setNetProfitMarginValue(val);
    }
    if (Number(val) < Number(grossProfitMarginValue)) {
      setNetProfitMarginValue(value);
    }
  };

  const updateImpactValue = (e) => {
    const { value } = e.currentTarget;
    let val = value;
    if (val > 10) {
      val = 10;
    }
    if (val < 0) {
      val = 0;
    }
    setImpactValue(val);
  };

  // const gross_profit = (annualRevenueValue * grossProfitMarginValue) / 100;
  const net_profit = (annualRevenueValue * netProfitMarginValue.floatValue) / 100;

  const overall_expenses = annualRevenueValue - net_profit;

  const current_value_impact = impactValue / 100;

  const cut_costs_revenue = 0;
  const cut_costs = Math.round(overall_expenses * (impact_value_cut_cost / 100));
  const market_dominating_position_revenue = (impact_value_market_dominating_position / 100) * annualRevenueValue;
  const market_dominating_position = Math.round(market_dominating_position_revenue * (grossProfitMarginValue) / 100);
  const compelling_offer_revenue = (parseFloat(annualRevenueValue) + market_dominating_position_revenue) * (impact_value_compelling_offer / 100);
  const compelling_offer = Math.round(compelling_offer_revenue * (grossProfitMarginValue) / 100);
  const increase_prices_revenue = (parseFloat(annualRevenueValue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_increase_prices / 100);
  const increase_prices = Math.round(increase_prices_revenue);
  const upsell_cross_sell_revenue = (parseFloat(annualRevenueValue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_upsell_cross_sell / 100);
  const upsell_cross_sell = Math.round(upsell_cross_sell_revenue * ((grossProfitMarginValue) / 100));
  const bundling_revenue = (parseFloat(annualRevenueValue) + parseFloat(upsell_cross_sell_revenue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_bundling / 100);
  const bundling = Math.round(bundling_revenue * ((grossProfitMarginValue) / 100));
  const downsell_revenue = (parseFloat(annualRevenueValue) + parseFloat(bundling_revenue) + parseFloat(upsell_cross_sell_revenue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_downsell / 100);
  const downsell = Math.round(downsell_revenue * (grossProfitMarginValue) / 100);
  const additional_products_services_revenue = (parseFloat(annualRevenueValue) + parseFloat(downsell_revenue) + parseFloat(bundling_revenue) + parseFloat(upsell_cross_sell_revenue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_additional_products_services / 100);
  const additional_products_services = Math.round(additional_products_services_revenue * (grossProfitMarginValue) / 100);
  const drip_campaign_revenue = (parseFloat(annualRevenueValue) + parseFloat(additional_products_services_revenue) + parseFloat(downsell_revenue) + parseFloat(bundling_revenue) + parseFloat(upsell_cross_sell_revenue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_drip_campaign / 100);
  const drip_campaign = Math.round((drip_campaign_revenue * grossProfitMarginValue) / 100);
  const alliances_joint_ventures_revenue = (parseFloat(annualRevenueValue) + parseFloat(drip_campaign_revenue) + parseFloat(additional_products_services_revenue) + parseFloat(downsell_revenue) + parseFloat(bundling_revenue) + parseFloat(upsell_cross_sell_revenue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_alliances_joint_ventures / 100);
  const alliances_joint_ventures = Math.round(alliances_joint_ventures_revenue * (grossProfitMarginValue) / 100);
  const more_leads_revenue = (parseFloat(annualRevenueValue) + parseFloat(alliances_joint_ventures_revenue) + parseFloat(drip_campaign_revenue) + parseFloat(additional_products_services_revenue) + parseFloat(downsell_revenue) + parseFloat(bundling_revenue) + parseFloat(upsell_cross_sell_revenue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_more_leads / 100);
  const more_leads = Math.round(more_leads_revenue * (grossProfitMarginValue) / 100);
  const digital_marketing_revenue = (parseFloat(annualRevenueValue) + parseFloat(more_leads_revenue) + parseFloat(alliances_joint_ventures_revenue) + parseFloat(drip_campaign_revenue) + parseFloat(additional_products_services_revenue) + parseFloat(downsell_revenue) + parseFloat(bundling_revenue) + parseFloat(upsell_cross_sell_revenue) + parseFloat(increase_prices_revenue) + parseFloat(market_dominating_position_revenue) + parseFloat(compelling_offer_revenue)) * (impact_value_digital_marketing / 100);
  const digital_marketing = Math.round((digital_marketing_revenue * grossProfitMarginValue) / 100);


  let table_one_data = [];
  table_one_data = [
    {
      id: 1,
      item: 'Cut Costs',
      impact: impact_value_cut_cost,
      revenue_increase: cut_costs_revenue,
      amount: cut_costs,
    },
    {
      id: 2,
      item: 'Market Dominating Position',
      impact: impact_value_market_dominating_position,
      revenue_increase: market_dominating_position_revenue,
      amount: market_dominating_position,
    },
    {
      id: 3,
      item: 'Compelling Offer',
      impact: impact_value_compelling_offer,
      revenue_increase: compelling_offer_revenue,
      amount: compelling_offer,
    },
    {
      id: 4,
      item: 'Increase Prices',
      impact: impact_value_increase_prices,
      revenue_increase: increase_prices_revenue,
      amount: increase_prices,
    },
    {
      id: 5,
      item: 'Upsell & Cross-sell',
      impact: impact_value_upsell_cross_sell,
      revenue_increase: upsell_cross_sell_revenue,
      amount: upsell_cross_sell,
    },
    {
      id: 6,
      item: 'Bundling',
      impact: impact_value_bundling,
      revenue_increase: bundling_revenue,
      amount: bundling,
    },
    {
      id: 7,
      item: 'Downsell',
      impact: impact_value_downsell,
      revenue_increase: downsell_revenue,
      amount: downsell,
    },
    {
      id: 8,
      item: 'Additional Products & Services',
      impact: impact_value_additional_products_services,
      revenue_increase: additional_products_services_revenue,
      amount: additional_products_services,
    },
    {
      id: 9,
      item: 'Drip Campaign',
      impact: impact_value_drip_campaign,
      revenue_increase: drip_campaign_revenue,
      amount: drip_campaign,
    },
    {
      id: 10,
      item: 'Alliances & Joint Ventures',
      impact: impact_value_alliances_joint_ventures,
      revenue_increase: alliances_joint_ventures_revenue,
      amount: alliances_joint_ventures,
    },
    {
      id: 11,
      item: 'More Leads',
      impact: impact_value_more_leads,
      revenue_increase: more_leads_revenue,
      amount: more_leads,
    },
    {
      id: 12,
      item: 'Digital Marketing',
      impact: impact_value_digital_marketing,
      revenue_increase: digital_marketing_revenue,
      amount: digital_marketing,
    },
  ];

  const increase_in_revenue = table_one_data.map((item) => item.revenue_increase);
  const expected_increase_in_revenue = Math.round(sum(increase_in_revenue));

  const new_annual_gross_revenue = parseInt(annualRevenueValue, 10) + parseInt(expected_increase_in_revenue, 10);

  const total_profit_impact_list = table_one_data.map((item) => item.amount);
  const total_profit_impact = sum(total_profit_impact_list);

  const percentage_total_profit_impact = Math.floor((total_profit_impact / net_profit) * 100);
  const new_annual_profit = total_profit_impact + net_profit;
  const five_year_profit_impact = total_profit_impact * 5;

  const value = process.env.REACT_APP_CALCULATOR_VALUE;
  const current_valuation = Math.round(annualRevenueValue * value);


  // data for graph one
  const graph_data = {
    current_profit: net_profit,
    expected_profit_increase: total_profit_impact + net_profit,
    current_revenue: parseInt(annualRevenueValue, 10),
    expected_revenue_increase: new_annual_gross_revenue,
    current_valuation: Math.round(annualRevenueValue * value),
    expected_valuation_increase: Math.round(expected_increase_in_revenue * value) + current_valuation,
  };

  // data for table two
  const content_marketing_revenue = ((parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact);
  const content_marketing = Math.round(content_marketing_revenue * (grossProfitMarginValue) / 100);
  const website_optimization_revenue = (parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const website_optimization = Math.round(website_optimization_revenue * (grossProfitMarginValue) / 100);
  const email_marketing_revenue = (parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const email_marketing = Math.round(email_marketing_revenue * (grossProfitMarginValue) / 100);
  const search_engine_optimization_revenue = (parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const search_engine_optimization = Math.round(search_engine_optimization_revenue * (grossProfitMarginValue) / 100);
  const digital_advertising_revenue = (parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const digital_advertising = Math.round(digital_advertising_revenue * (grossProfitMarginValue) / 100);
  const social_media_revenue = (parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const social_media = Math.round(social_media_revenue * (grossProfitMarginValue) / 100);
  const video_marketing_revenue = (parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const video_marketing = Math.round(video_marketing_revenue * (grossProfitMarginValue) / 100);
  const metrics_marketing_revenue = (parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const metrics_marketing = Math.round(metrics_marketing_revenue * (grossProfitMarginValue) / 100);
  const strategy_revenue = (parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const strategy = Math.round(strategy_revenue * (grossProfitMarginValue) / 100);
  const trust_expertise_education_revenue = (parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const trust_expertise_education = Math.round(trust_expertise_education_revenue * (grossProfitMarginValue) / 100);
  const policies_procedures_revenue = (parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const policies_procedures = Math.round(policies_procedures_revenue * (grossProfitMarginValue) / 100);
  const referral_systems_revenue = (parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const referral_systems = Math.round(referral_systems_revenue * (grossProfitMarginValue) / 100);
  const publicity_pr_revenue = (parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const publicity_pr = Math.round(publicity_pr_revenue * (grossProfitMarginValue) / 100);
  const direct_mail_revenue = (parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const direct_mail = Math.round(direct_mail_revenue * (grossProfitMarginValue) / 100);
  const advertising_revenue = (parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const advertising = Math.round(advertising_revenue * (grossProfitMarginValue) / 100);
  const scripts_revenue = (parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const scripts = Math.round(scripts_revenue * (grossProfitMarginValue) / 100);
  const initial_close_rate_revenue = (parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const initial_close_rate = Math.round(initial_close_rate_revenue * (grossProfitMarginValue) / 100);
  const follow_up_close_rate_revenue = (parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const follow_up_close_rate = Math.round(follow_up_close_rate_revenue * (grossProfitMarginValue) / 100);
  const sales_team_revenue = (parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const sales_team = Math.round(sales_team_revenue * (grossProfitMarginValue) / 100);
  const more_appointments_revenue = (parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const more_appointments = Math.round(more_appointments_revenue * (grossProfitMarginValue) / 100);
  const increase_frequency_of_purchase_revenue = (parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const increase_frequency_of_purchase = Math.round(increase_frequency_of_purchase_revenue * (grossProfitMarginValue) / 100);
  const increase_longevity_buying_relationship_revenue = (parseFloat(increase_frequency_of_purchase_revenue) + parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const increase_longevity_buying_relationship = Math.round(increase_longevity_buying_relationship_revenue * (grossProfitMarginValue) / 100);
  const sales_training_revenue = (parseFloat(increase_longevity_buying_relationship_revenue) + parseFloat(increase_frequency_of_purchase_revenue) + parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const sales_training = Math.round(sales_training_revenue * (grossProfitMarginValue) / 100);
  const more_profitable_trade_shows_revenue = (parseFloat(sales_training_revenue) + parseFloat(increase_longevity_buying_relationship_revenue) + parseFloat(increase_frequency_of_purchase_revenue) + parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const more_profitable_trade_shows = Math.round(more_profitable_trade_shows_revenue * (grossProfitMarginValue) / 100);
  const dealing_with_decision_makers_revenue = (parseFloat(more_profitable_trade_shows_revenue) + parseFloat(sales_training_revenue) + parseFloat(increase_longevity_buying_relationship_revenue) + parseFloat(increase_frequency_of_purchase_revenue) + parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const dealing_with_decision_makers = Math.round(dealing_with_decision_makers_revenue * (grossProfitMarginValue) / 100);
  const attracting_dream_clients_revenue = (parseFloat(dealing_with_decision_makers_revenue) + parseFloat(more_profitable_trade_shows_revenue) + parseFloat(sales_training_revenue) + parseFloat(increase_longevity_buying_relationship_revenue) + parseFloat(increase_frequency_of_purchase_revenue) + parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const attracting_dream_clients = Math.round(attracting_dream_clients_revenue * (grossProfitMarginValue) / 100);
  const order_fullfillment_revenue = (parseFloat(attracting_dream_clients_revenue) + parseFloat(dealing_with_decision_makers_revenue) + parseFloat(more_profitable_trade_shows_revenue) + parseFloat(sales_training_revenue) + parseFloat(increase_longevity_buying_relationship_revenue) + parseFloat(increase_frequency_of_purchase_revenue) + parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const order_fullfillment = Math.round(order_fullfillment_revenue * (grossProfitMarginValue) / 100);
  const overcoming_buyers_remorse_revenue = (parseFloat(order_fullfillment_revenue) + parseFloat(attracting_dream_clients_revenue) + parseFloat(dealing_with_decision_makers_revenue) + parseFloat(more_profitable_trade_shows_revenue) + parseFloat(sales_training_revenue) + parseFloat(increase_longevity_buying_relationship_revenue) + parseFloat(increase_frequency_of_purchase_revenue) + parseFloat(more_appointments_revenue) + parseFloat(sales_team_revenue) + parseFloat(follow_up_close_rate_revenue) + parseFloat(initial_close_rate_revenue) + parseFloat(scripts_revenue) + parseFloat(advertising_revenue) + parseFloat(direct_mail_revenue) + parseFloat(publicity_pr_revenue) + parseFloat(referral_systems_revenue) + parseFloat(policies_procedures_revenue) + parseFloat(trust_expertise_education_revenue) + parseFloat(strategy_revenue) + parseFloat(metrics_marketing_revenue) + parseFloat(video_marketing_revenue) + parseFloat(social_media_revenue) + parseFloat(digital_advertising_revenue) + parseFloat(search_engine_optimization_revenue) + parseFloat(email_marketing_revenue) + parseFloat(website_optimization_revenue) + parseFloat(content_marketing_revenue) + parseFloat(expected_increase_in_revenue) + parseFloat(annualRevenueValue)) * current_value_impact;
  const overcoming_buyers_remorse = Math.round(overcoming_buyers_remorse_revenue * (grossProfitMarginValue) / 100);


  const table_two_data = [
    {
      id: 13,
      item: 'Content Marketing',
      revenue_increase: content_marketing_revenue,
      amount: content_marketing,
    },
    {
      id: 14,
      item: 'Website Optimization',
      revenue_increase: website_optimization_revenue,
      amount: website_optimization,
    },
    {
      id: 15,
      item: 'Email Marketing',
      revenue_increase: email_marketing_revenue,
      amount: email_marketing,
    },
    {
      id: 16,
      item: 'Search Engine Optimization',
      revenue_increase: search_engine_optimization,
      amount: search_engine_optimization,
    },
    {
      id: 17,
      item: 'Digital Advertising',
      revenue_increase: digital_advertising_revenue,
      amount: digital_advertising,
    },
    {
      id: 18,
      item: 'Social Media',
      revenue_increase: social_media_revenue,
      amount: social_media,
    },
    {
      id: 19,
      item: 'Video Marketing',
      revenue_increase: video_marketing_revenue,
      amount: video_marketing,
    },
    {
      id: 20,
      item: 'Metrics Marketing',
      revenue_increase: metrics_marketing_revenue,
      amount: metrics_marketing,
    },
    {
      id: 21,
      item: 'Strategy',
      revenue_increase: strategy_revenue,
      amount: strategy,
    },
    {
      id: 22,
      item: 'Trust, Expertise, Education',
      revenue_increase: trust_expertise_education_revenue,
      amount: trust_expertise_education,
    },
    {
      id: 23,
      item: 'Policies & Procedures',
      revenue_increase: policies_procedures_revenue,
      amount: policies_procedures,
    },
    {
      id: 24,
      item: 'Referral Systems',
      revenue_increase: referral_systems_revenue,
      amount: referral_systems,
    },
    {
      id: 25,
      item: 'Publicity & PR',
      revenue_increase: publicity_pr_revenue,
      amount: publicity_pr,
    },
    {
      id: 26,
      item: 'Direct Mail',
      revenue_increase: direct_mail_revenue,
      amount: direct_mail,
    },
    {
      id: 27,
      item: 'Advertising',
      revenue_increase: advertising_revenue,
      amount: advertising,
    },
    {
      id: 28,
      item: 'Scripts',
      revenue_increase: scripts_revenue,
      amount: scripts,
    },
    {
      id: 29,
      item: 'Initial Close Rate',
      revenue_increase: initial_close_rate_revenue,
      amount: initial_close_rate,
    },
    {
      id: 30,
      item: 'Follow Up Close Rate',
      revenue_increase: follow_up_close_rate_revenue,
      amount: follow_up_close_rate,
    },
    {
      id: 31,
      item: 'Sales Team',
      revenue_increase: sales_team_revenue,
      amount: sales_team,
    },
    {
      id: 32,
      item: 'More Appointments',
      revenue_increase: more_appointments_revenue,
      amount: more_appointments,
    },
    {
      id: 33,
      item: 'Increase Frequency of Purchase',
      revenue_increase: increase_frequency_of_purchase_revenue,
      amount: increase_frequency_of_purchase,
    },
    {
      id: 34,
      item: 'Increase Longevity of Buying Relationship',
      revenue_increase: increase_longevity_buying_relationship_revenue,
      amount: increase_longevity_buying_relationship,
    },
    {
      id: 35,
      item: 'Sales Training',
      revenue_increase: sales_training_revenue,
      amount: sales_training,
    },
    {
      id: 36,
      item: 'More Profitable Trade Shows',
      revenue_increase: more_profitable_trade_shows_revenue,
      amount: more_profitable_trade_shows,
    },
    {
      id: 37,
      item: 'Dealing with Decision Makers',
      revenue_increase: dealing_with_decision_makers_revenue,
      amount: dealing_with_decision_makers,
    },
    {
      id: 38,
      item: 'Attracting Dream Clients',
      revenue_increase: attracting_dream_clients_revenue,
      amount: attracting_dream_clients,
    },
    {
      id: 39,
      item: 'Order Fullfillment',
      revenue_increase: order_fullfillment_revenue,
      amount: order_fullfillment,
    },
    {
      id: 40,
      item: 'Overcoming Buyer`s Remorse',
      revenue_increase: overcoming_buyers_remorse_revenue,
      amount: overcoming_buyers_remorse,
    },
  ];


  const increase_in_revenue_deep_dive = table_two_data.map((item) => item.revenue_increase);
  const expected_increase_in_revenue_deep_dive = Math.round(sum(increase_in_revenue_deep_dive));

  const new_annual_gross_revenue_deep_dive = parseInt(annualRevenueValue, 10) + parseInt(expected_increase_in_revenue_deep_dive, 10);

  const total_profit_impact_list_deep_dive = table_two_data.map((item) => item.amount);
  const total_profit_impact_deep_dive = sum(total_profit_impact_list_deep_dive) + total_profit_impact;
  const percentage_total_profit_impact_deep_dive = Math.floor((total_profit_impact_deep_dive / net_profit) * 100);

  const new_annual_profit_deep_dive = total_profit_impact_deep_dive + net_profit;
  const five_year_profit_impact_deep_dive = total_profit_impact_deep_dive * 5;

  // data for deep dive graph 
  const graph_data_deep_dive = {
    current_profit: net_profit,
    expected_profit_increase: total_profit_impact_deep_dive + net_profit,
    current_revenue: parseInt(annualRevenueValue, 10),
    expected_revenue_increase: new_annual_gross_revenue_deep_dive,
    current_valuation: Math.round(annualRevenueValue * value),
    expected_valuation_increase: Math.round(expected_increase_in_revenue_deep_dive * value) + current_valuation,
  };

  const starting_valuation = Math.round(annualRevenueValue * value);
  const potential_valuation = Math.round(expected_increase_in_revenue_deep_dive * value) + starting_valuation;

  // updateImpactValues is a function that updates all related state variables
  const updateImpactValues = async (value) => {
    setImpactValue(value);
    set_impact_value_cut_cost(value);
    set_impact_value_market_dominating_position(value);
    set_impact_value_compelling_offer(value);
    set_impact_value_increase_prices(value);
    set_impact_value_upsell_cross_sell(value);
    set_impact_value_bundling(value);
    set_impact_value_downsell(value);
    set_impact_value_additional_products_services(value);
    set_impact_value_drip_campaign(value);
    set_impact_value_alliances_joint_ventures(value);
    set_impact_value_more_leads(value);
    set_impact_value_digital_marketing(value);
  };

  const handleRangeChange = (e) => {
    const { value, min, max } = e.currentTarget;
    // Calculate the percentage for styling
    // This calculates the slider's position as a percentage of the total range
    const val = ((parseFloat(value) - parseFloat(min)) / (parseFloat(max) - parseFloat(min))) * 100;

    // Use a state to store the slider style with one decimal precision
    setSliderStyle(val.toFixed(1));
    // Update impact values with a single state or context
    updateImpactValues(value);
  };
  // Apply the style using the state
  const simSliderStyle = {
    backgroundImage: `linear-gradient(90deg, #019247 ${sliderStyle}%, #dee2e6 ${sliderStyle}%)`,
    transition: 'background-image 0.1s ease-in-out',
  };

  const handleSaveData = async (values) => {
    setIsLoading(true); // Start loading

    const { first_name, last_name, email } = values;
    const { uuid } = linkData.data;
    const form_data = {
      id: uuid,
      firstname: first_name,
      lastname: last_name,
      useremail: email,
      report_redirect_url: baseUrl,
      annualrevenue: annualRevenueValue,
      grossprofitmargin: grossProfitMarginValue,
      netprofitmargin: netProfitMarginValue.floatValue,
      percentageimpact: impactValue,
      currency: currencyValue,
      netprofit: net_profit,
      newannualprofit: new_annual_profit,
      cutcosts: cut_costs,
      revenueincrease_cut_costs: Math.round(cut_costs_revenue),
      marketdominatingposition: market_dominating_position,
      revenueincrease_market_dominating_position: Math.round(market_dominating_position_revenue),
      compellingoffer: compelling_offer,
      revenueincrease_compelling_offer: Math.round(compelling_offer_revenue),
      increaseprices: increase_prices,
      revenueincrease_increase_prices: Math.round(increase_prices_revenue),
      upsellcrosssell: upsell_cross_sell,
      revenueincrease_upsell_cross_sell: Math.round(upsell_cross_sell_revenue),
      thebundling: bundling,
      revenueincrease_bundling: Math.round(bundling_revenue),
      thedownsell: downsell,
      revenueincrease_downsell: Math.round(downsell_revenue),
      additionalproductsservices: additional_products_services,
      revenueincrease_additional_products_services_revenue: Math.round(additional_products_services_revenue),
      dripcampaign: drip_campaign,
      revenueincrease_drip_campaign: Math.round(drip_campaign_revenue),
      alliancesjointventures: alliances_joint_ventures,
      revenueincrease_alliances_joint_ventures: Math.round(alliances_joint_ventures_revenue),
      moreleads: more_leads,
      revenueincrease_more_leads: Math.round(more_leads_revenue),
      digitalmarketing: digital_marketing,
      revenueincrease_digital_marketing: Math.round(digital_marketing_revenue),
      contentmarketing: content_marketing,
      revenueincrease_content_marketing: Math.round(content_marketing_revenue),
      websiteoptimization: website_optimization,
      revenueincrease_website_optimization: Math.round(website_optimization_revenue),
      emailmarketing: email_marketing,
      revenueincrease_email_marketing: Math.round(email_marketing_revenue),
      searchengineoptimization: search_engine_optimization,
      revenueincrease_search_engine_optimization: Math.round(search_engine_optimization_revenue),
      digitaladvertising: digital_advertising,
      revenueincrease_digital_advertising: Math.round(digital_advertising_revenue),
      socialmedia: social_media,
      revenueincrease_social_media: Math.round(social_media_revenue),
      videomarketing: video_marketing,
      revenueincrease_video_marketing: Math.round(video_marketing_revenue),
      metricsmarketing: metrics_marketing,
      revenueincrease_metrics_marketing: Math.round(metrics_marketing_revenue),
      thestrategy: strategy,
      revenueincrease_strategy: Math.round(strategy_revenue),
      trustexpertiseeducation: trust_expertise_education,
      revenueincrease_trust_expertise_education: Math.round(trust_expertise_education_revenue),
      policiesprocedures: policies_procedures,
      revenueincrease_policies_procedures: Math.round(policies_procedures_revenue),
      referralsystems: referral_systems,
      revenueincrease_referral_systems: Math.round(referral_systems_revenue),
      publicitypr: publicity_pr,
      revenueincrease_publicity_pr: Math.round(publicity_pr_revenue),
      directmail: direct_mail,
      revenueincrease_direct_mail: Math.round(direct_mail_revenue),
      theadvertising: advertising,
      revenueincrease_advertising: Math.round(advertising_revenue),
      thescripts: scripts,
      revenueincrease_scripts: Math.round(scripts_revenue),
      initialcloserate: initial_close_rate,
      revenueincrease_initial_close_rate: Math.round(initial_close_rate_revenue),
      followupcloserate: follow_up_close_rate,
      revenueincrease_follow_up_close_rate: Math.round(follow_up_close_rate_revenue),
      salesteam: sales_team,
      revenueincrease_sales_team: Math.round(sales_team_revenue),
      moreappointments: more_appointments,
      revenueincrease_more_appointments: Math.round(more_appointments_revenue),
      increasefrequencyofPurchase: increase_frequency_of_purchase,
      revenueincrease_increase_frequency_of_purchase: Math.round(increase_frequency_of_purchase_revenue),
      increaselongevityofbuyingrelationship: increase_longevity_buying_relationship,
      revenueincrease_increase_longevity_buying_relationship: Math.round(increase_longevity_buying_relationship_revenue),
      salestraining: sales_training,
      revenueincrease_sales_training: Math.round(sales_training_revenue),
      moreprofitabletradeshows: more_profitable_trade_shows,
      revenueincrease_more_profitable_trade_shows: Math.round(more_profitable_trade_shows_revenue),
      dealingwithdecisionmakers: dealing_with_decision_makers,
      revenueincrease_dealing_with_decision_makers: Math.round(dealing_with_decision_makers_revenue),
      attractingdreamclients: attracting_dream_clients,
      revenueincrease_attracting_dream_clients: Math.round(attracting_dream_clients_revenue),
      orderfullfillment: order_fullfillment,
      revenueincrease_order_fullfillment: Math.round(order_fullfillment_revenue),
      overcomingbuyersremorse: overcoming_buyers_remorse,
      revenueincrease_overcoming_buyers_remorse: Math.round(overcoming_buyers_remorse_revenue),
    };

    setIsLoading(true); // Start loading

    const post_url = process.env.REACT_APP_API_URL_PROD;

    axios({
      method: 'post',
      url: `${post_url}/api/simulation/add`,
      data: JSON.parse(JSON.stringify(form_data)),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        setSubmissionSuccess(true);
        setIsLoading(false);
        modalToggle(); // Optionally close the modal on success
        window.location.href = FULLPATH;
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  // const handleErrorsAndToggle = (errors, toggle, setToggle) => {
  //   // Define the fields with their corresponding IDs or refs
  //   const fieldRefs = {
  //     annualRevenue: document.getElementById('annualRevenue'),
  //     grossProfitMargin: document.getElementById('grossProfitMargin'),
  //     netProfitMargin: document.getElementById('netProfitMargin'),
  //   };

  //   // Check for errors in the fields and focus the first invalid field
  //   for (const [field, error] of Object.entries(errors)) {
  //     if (error) {
  //       // Close the toggle if it's open
  //       if (toggle) {
  //         setToggle(false);
  //       }

  //       // Focus on the first invalid field
  //       fieldRefs[field]?.focus();
  //       break; // Exit after focusing the first invalid field
  //     }
  //   }
  // };

  // table one data
  const getRowsData = () => {
    const items = table_one_data;
    return items.map((row, index) => (
      <tr key={index} className="text-xs md:text-sm">
        <td className="row-id-data"><span>{row.id}</span></td>
        <td className="row-item-data px-2 py-2 text-left w-4/12 md:px-4 break-words">{row.item}</td>
        <td className="row-percentage-data px-2 py-2 text-right w-3/12 md:px-4">
          <FormGroup>
            <Input
              type="number"
              className="switch-form-input w-full min-w-0"
              onChange={(e) => updateValue(e.currentTarget.value, index, row.id)}
              placeholder="0"
              max={10}
              min={0}
              step="0.1"
              value={row.impact}
            />
          </FormGroup>
        </td>
        <td className="row-amount-data px-2 py-2 text-center w-3/12 md:px-4">
          <NumericFormat
            value={row.amount}
            displayType="text"
            thousandSeparator
            prefix={currencyValue}
          />
        </td>
      </tr>

    ));
  };


  return (
    <div className="min-h-screen flex flex-col overflow-hidden antialiased">
      {/* Header Section */}
      <Transition
        show
        enter="transition-transform duration-600"
        enterFrom="transform -translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-transform duration-600"
        leaveFrom="transform translate-x-0"
        leaveTo="transform -translate-x-full"
      >
        <header className="text-white p-4 flex flex-col items-center">
          <div className="w-4/5 bg-white flex justify-center items-center p-2 rounded-b">
            <div className="text-xl font-bold">
              {isAlternateLogo ? 'Alternate Logo'
                : <img className="logo" src={Logo} alt="Logo" />}
            </div>
          </div>
          {/* New Middle and Bottom Sections */}
          <div className="bg-white w-full flex items-center justify-center mt-2 rounded">
            <section className="min-w-3xl text-center bg-white p-8 shadow-lg rounded-lg mx-4">
              <h1 className="text-xl sm:text-2xl md:text-4xl font-bold text-gray-900 mb-4">
                Could it be that Everything You’ve Learned About Building a Successful Business is WRONG?
              </h1>
              <p className="text-gray-700 text-base sm:text-lg md:text-xl">
                Increase just <span className="font-semibold">12 areas in your business</span> by a meager <span className="font-semibold">1.4%</span> and your profits will <span className="font-semibold text-green-600">DOUBLE!</span>
                <span className="block mt-2 text-sm text-gray-500">*Based on a 10% net profit margin.</span>
              </p>
            </section>
          </div>
        </header>
      </Transition>

      {/* Main Content Area */}
      <Transition
        show
        enter="transition-transform duration-400"
        enterFrom="transform -translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-transform duration-400"
        leaveFrom="transform translate-x-0"
        leaveTo="transform -translate-x-full"
      >
        <main className="flex flex-1 flex-col p-4 space-y-4 h-full w-full">
          {/* Container Section */}
          <div className="flex flex-col space-y-4 lg:space-y-0 md:space-y-2 md:flex-col md:space-x-0 lg:space-x-4 lg:flex-row">
            <div className="flex-1 flex flex-col space-y-4">

              <div className="bg-white shadow rounded w-full">
                {/* <Header title="Text Container" /> */}
                <div className="bg-white shadow-lg rounded-lg p-6 mx-auto">
                  <div className="p-4 space-y-4">
                    {/* <div class="text-lg sm:text-xl font-semibold text-gray-900">
                    How can you generate immediate increases in both revenue and profits… and without spending an additional cent on marketing or advertising?
                  </div> */}
                    <div className="flex items-center mb-4">
                      <img src={Yellow_Triangle} alt="Yellow Arrow" className="w-10 h-12 mr-3" />
                      <span className="font-semibold text-base sm:text-lg md:text-lg">The key is to understand the power of exponential growth. If you take a penny and double it every day for 30 days, you’ll be more than a millionaire… you’ll have <span className="highlight">$5,368,709!</span>.</span>
                    </div>
                    {/* <p class="text-base sm:text-lg text-gray-700 leading-relaxed">
                    
                  </p> */}
                    <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
                      That’s the incredible power of exponential growth. We call it <span className="highlight-2">Profit Acceleration</span> and it’s what you’re about to experience.
                    </p>
                    <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
                      Success is achieved through small, incremental changes in multiple areas of your business. Below are 12 areas that generate immediate increases in both revenue and profits… and without spending an additional cent on marketing or advertising.
                    </p>
                    <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
                      In the 3 boxes below, enter your company’s revenue, gross, and net profit margins. Then simply move the slide bar to see the results if you impact each area by <span className="highlight-3">1% up to 10%</span>.
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow rounded w-full">
                {/* <Header title="Form" /> */}
                <div className="p-4">
                  {/* Form Content */}
                  <CalcInputContainer fluid>
                    <Formik
                      initialValues={{
                        annualRevenue: '',
                        grossProfitMargin: '',
                        grossProfitMarginValue: '',
                      }}
                      validationSchema={validationSchema}
                      validateOnBlur
                      onSubmit={(values, { setSubmitting }) => {
                        handleSaveData(values);
                        setSubmitting(false);
                      }}
                    >
                      {({ setFieldValue, validateForm, values, errors, handleBlur }) => (
                        <Row style={{ marginTop: '1.5%' }}>
                          <Col xs="12" md="6" lg="10" xl="6">
                            <FormGroup>
                              <div className="flex flex-col sm:flex-row items-start sm:items-center py-1 space-y-2 sm:space-y-0 sm:space-x-2">
                                <Label htmlFor="annualRevenue" className="text-sm sm:text-base md:text-lg">Annual Revenue</Label>
                                <Popup header="Insert Annual Revenue" id="popup-insert-annual-revenue">
                                  <span className="text-xs sm:text-sm md:text-base">
                                    Annual revenue is all the money your company earns from sales activity during a given year before costs and expenses are subtracted.
                                  </span>
                                </Popup>
                              </div>

                              <NumericFormat
                                className="w-full rounded-none"
                                name="annualRevenue"
                                id="annualRevenue"
                                placeholder="0"
                                thousandSeparator
                                allowNegative={false}
                                prefix={currencyValue}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  // Call your custom update function
                                  updateAnnualRevenue(value);
                                  // Let Formik handle the value update
                                  setFieldValue('annualRevenue', value);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e); // Perform Formik's blur handler
                                  checkForErrors(errors); // Check for errors after blur
                                }}
                              />

                              <ErrorMessage name="annualRevenue" component="span" className="text-red-600 text-sm" />
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="6" lg="10" xl="6">
                            {/* Gross Profit Margin Field */}
                            <FormGroup>
                              <div className="flex flex-col sm:flex-row items-start sm:items-center py-1 space-y-2 sm:space-y-0 sm:space-x-2">
                                <Label htmlFor="grossProfitMargin" className="text-sm sm:text-base md:text-lg">
                                  Gross Profit Margin (%)
                                </Label>
                                <Popup header="Gross Profit Margin" id="popup-gross-profit-margin">
                                  <span className="text-xs sm:text-sm md:text-base">
                                    To understand your GPM, subtract cost of goods sold from total revenue then divide that number by the total revenue.
                                    Basically, if you make an additional sale, you've already covered the rent, utilities, salaries, insurance, etc.
                                    Therefore, you can measure the increased sales by Gross Profit Margin.
                                  </span>
                                </Popup>
                              </div>

                              <NumericFormat
                                className="w-full rounded-none"
                                name="grossProfitMargin"
                                id="grossProfitMargin"
                                placeholder="0"
                                allowNegative={false}
                                suffix="%"
                                onValueChange={(values) => {
                                  const { value } = values;
                                  setFieldValue('grossProfitMargin', value);
                                  updateGrossProfitMargin(value);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e); // Perform Formik's blur handler
                                  checkForErrors(errors); // Check for errors after blur
                                }}
                              />
                              <ErrorMessage name="grossProfitMargin" component="span" className="text-red-600 text-sm" />
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="6" lg="10" xl="6">
                            <FormGroup>

                              <div className="flex flex-col sm:flex-row items-start sm:items-center py-1 space-y-2 sm:space-y-0 sm:space-x-2">
                                <Label for="netProfitMarginValue" className="text-sm sm:text-base md:text-lg">Net Profit Margin (%)</Label>
                                {/* Assuming Popup is a button or icon that triggers a modal or tooltip */}
                                <Popup header="Net Profit Margin" id="popup-net-profit-margin">
                                  <span className="text-xs sm:text-sm md:text-base">
                                    This is what is left over after all the bills are paid. This percentage is ALWAYS lower than Gross Profit Margin.
                                  </span>
                                </Popup>
                              </div>
                              <NumericFormat
                                className="w-full rounded-none"
                                name="netProfitMargin"
                                id="netProfitMargin"
                                placeholder="0"
                                allowNegative={false}
                                suffix="%"
                                onBlur={(e) => {
                                  handleBlur(e); // Perform Formik's blur handler
                                  checkForErrors(errors); // Check for errors after blur
                                }}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  setFieldValue('netProfitMargin', value);
                                  checkNetProfitValue(values);
                                }}
                              />
                              <ErrorMessage name="netProfitMargin" component="span" className="text-red-600 text-sm" />
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="6" lg="10" xl="6"> {/* Responsive column size */}
                            <FormGroup>
                              <div className="flex flex-col sm:flex-row items-start sm:items-center py-1 space-y-2 sm:space-y-0 sm:space-x-2">
                                <Label for="currencySelect" className="text-sm sm:text-base md:text-lg">Select Currency</Label>

                              </div>
                              <SimSelect
                                id="currencySelect"
                                name="currency"
                                options={currencies}
                                value={currencies.label}
                                onChange={onSelectChange}
                                classNamePrefix="react-select" // For custom styling if needed
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </Formik>
                    {/* Continue with other sections */}
                    {/* Slider, Table, etc. */}
                  </CalcInputContainer>

                  <section className="bg-white border-t-4 border-[#009247] rounded-b shadow-md px-4 py-3 my-4" role="alert">
                    <p className="font-bold">If you want to double your profits, you may only need a 1.4% increase in each of the 12 areas.</p>
                    <p className="text-sm">If you don’t know your margins, use 50% for gross margin and 10% for net margin.</p>
                  </section>

                  <section className="flex flex-col md:flex-row sm:flex-row md:space-x-4 space-y-4 md:space-y-0">
                    <div className="w-full lg:w-1/2 md:w-1/2 text-center">
                      {/* Text content here */}
                      <span className="block sm:text-base font-medium">Slide to Choose your impact between 1% and 10%</span>
                    </div>
                    <section className="flex md:flex-row pb-8 md:w-10/12">
                      <div className="w-2/3 text-center">
                        {/* Range slider here */}
                        <section className="!flex !flex-col h-12 p-4 md:flex-row md:items-start space-y-4 md:space-y-0">
                          <Input
                            type="range"
                            className="slider-input-range !w-full mt-2 bg-gray-dark"
                            id="slider-input-range"
                            style={simSliderStyle}
                            max={10}
                            min={0}
                            value={impactValue}
                            step="0.1"
                            onChange={(e) => handleRangeChange(e)}
                          />
                          <div className="directional-arrows">
                            <img src={DirectionArrows} alt="Direction Arrows" className="directional_arrows_img" />
                          </div>
                        </section>
                      </div>
                      <div className="w-1/3 text-center">
                        {/* Number input here */}
                        <section className="slider-input-container w-full">
                          <FormGroup>
                            <Input
                              type="number"
                              className="lg:w-[111%] md:w-[140%] sm:w-[110%] mt-2 md:mt-0 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              onChange={(e) => updateImpactValue(e)}
                              max={10}
                              min={0}
                              step="0.1"
                              value={impactValue}
                              onInput={(e) => handleRangeChange(e)}
                            />
                          </FormGroup>
                        </section>
                      </div>
                    </section>
                  </section>

                </div>
              </div>
            </div>
            <div className="flex-1 bg-white shadow rounded w-full">
              {/* <Header title="Video" /> */}
              <div className="p-4">
                {/* Video Content */}
                <VideoOne
                  image={VideoImageOne}
                  video={video_1}
                />
              </div>
            </div>
          </div>

          {/* Table and Graph Section */}
          <div className="flex flex-col md:flex-col lg:flex-col xl:flex-row space-y-4 md:space-y-4 lg:space-y-4 xl:space-y-0 md:space-x-0 xl:space-x-4 lg:space-x-0">
            <div className="flex-1 bg-white shadow rounded w-full">
              {/* <Header title="Table 1" /> */}
              <div className="!p-4 overflow-x-auto md:overflow-visible">
                {/* Table 1 Content */}
                <table className="min-w-full bg-white table-data animate__animated animate__fadeIn">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 w-1/12"> </th>
                      <th className="text-xs sm:text-sm md:text-base px-4 py-2 text-left sm:w-3/12 lg:w-5/12 uppercase">Item</th>
                      <th className="text-xs sm:text-sm md:text-base px-4 py-2 text-center lg:w-2/12 uppercase">% Impact</th>
                      <th className="text-xs sm:text-sm md:text-base px-4 py-2 text-center sm:text-left sm:w-6/12 lg:w-4/12 uppercase">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getRowsData()}
                    <tr>
                      <td className=""> </td>
                      <td className="px-0 py-2 md:px-2 md:py-2 text-left font-semibold">Total Profit Impact</td>
                      <td className="text-center"> </td>
                      <td className="px-0 py-2 md:px-2 md:py-2 text-center font-semibold text-sm md:text-base text-wrap break-words">
                        <NumericFormat value={total_profit_impact} displayType="text" thousandSeparator prefix={currencyValue} />
                        <span className="ml-2">/</span>
                        <span className="ml-2">{isNaN(percentage_total_profit_impact) ? 0 : percentage_total_profit_impact}% +</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex-1 bg-white shadow rounded w-full overflow-y-auto max-h-screen lg:max-h-[101vh] md:max-h-[70vh] sm:max-h-[55vh]">
              {/* <Header title="Graph 1" /> */}
              <div className="p-4">
                {/* Graph 1 Content */}
                <GraphOne data={graph_data} currency={currencyValue} />
              </div>
            </div>
          </div>

          {/* Summary Table and Buttons Section */}
          <div className="bg-white shadow rounded w-full min-w-3xl mx-auto">
            <div className="p-4 border-[#fabf08] border-2 py-1">
              <div className="overflow-x-auto md:overflow-visible">
                <table className="w-full bg-white table-auto border-collapse animate__animated animate__fadeIn">
                  <tbody>
                    <tr className="bg-white even:bg-gray-50">
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><b>Current Revenue</b></td>
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={annualRevenueValue} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><b>Current Profit</b></td>
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={net_profit} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                    </tr>
                    <tr className="bg-white even:bg-gray-50">
                      <td className="px-4 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><b>Expected Increase in Revenue</b></td>
                      <td className="px-4 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={expected_increase_in_revenue} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                      <td className="px-4 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><b>New Annual Profit</b></td>
                      <td className="px-4 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={new_annual_profit} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                    </tr>
                    <tr className="bg-white even:bg-gray-50">
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><b>New Annual Gross Revenue</b></td>
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={new_annual_gross_revenue} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><b>5-Year Profit Impact</b></td>
                      <td className="px-4 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={five_year_profit_impact} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center space-x-4 mt-4">

            <Button className="bottom-button-one m-auto lg:!w-1/3 sm:!w-3/4 shadow" onClick={handleAutoScroll}>Deep Dive Your Profits Further {(toggle) ? <i className="fa fa-chevron-right animate-arrow animate-arrow-down" /> : <i className="fa fa-chevron-right animate-arrow animate-arrow-up" />}</Button>
            <Button className="sm:!w-1/2 lg:w-5/12 m-auto my-3 shadow" color="primary" onClick={modalToggleHyper} outline> I don't want to deep dive my profits further. Just send me the report. </Button>

          </div>

          {/* Hidden Section */}
          {/* <Header title="Table 2" /> */}
          {isHiddenSectionVisible && (
            <div className="bg-gray-100 p-4 shadow rounded mt-4 space-y-4" ref={newSectionRef}>
              {/* Table and Graph Section */}
              <div className="flex flex-col md:flex-col lg:flex-col xl:flex-row space-y-4 md:space-y-4 lg:space-y-4 xl:space-y-0 md:space-x-0 lg:space-x-0">
                <div className="flex-1 bg-white shadow rounded w-full">
                  <div className="p-4 overflow-y-auto overflow-x-auto max-h-screen md:max-h-[70vh] sm:max-h-[55vh]">
                    {/* Table 2 Content */}
                    <TableTwo data={table_two_data} total_profit_impact={total_profit_impact_deep_dive} percentage_total_profit_impact={percentage_total_profit_impact_deep_dive} currency={currencyValue} />
                  </div>
                </div>
                <div className="flex-1 bg-white shadow rounded w-full overflow-y-auto max-h-screen md:max-h-[70vh] sm:max-h-[55vh]">
                  {/* <Header title="Graph 2" /> */}
                  <div className="p-4">
                    {/* Graph 2 Content */}
                    <GraphTwo data={graph_data_deep_dive} currency={currencyValue} />
                  </div>
                </div>
              </div>

              {/* Summary Section */}
              <div className="bg-white shadow rounded w-full min-w-3xl mx-auto">
                <div className="p-4">
                  <section className="middle-data border-[#fabf08] border-2 p-3">
                    <div className="overflow-x-auto md:overflow-visible">
                      <table className="w-full bg-white table-auto border-collapse animate__animated animate__fadeIn">
                        <tbody>
                          <tr className="bg-white even:bg-gray-50">
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><b>Current Revenue</b></td>
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={annualRevenueValue} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><b>Current Profit</b></td>
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={net_profit} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                          </tr>
                          <tr className="bg-white even:bg-gray-50">
                            <td className="px-2 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><b>Expected Increase in Revenue</b></td>
                            <td className="px-2 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={expected_increase_in_revenue_deep_dive} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                            <td className="px-2 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><b>New Annual Profit</b></td>
                            <td className="px-2 py-2 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={new_annual_profit_deep_dive} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                          </tr>
                          <tr className="bg-white even:bg-gray-50">
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><b>New Annual Gross Revenue</b></td>
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={new_annual_gross_revenue_deep_dive} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><b>5-Year Profit Impact</b></td>
                            <td className="px-2 py-2 bg-gray-200 text-gray-700 text-xs sm:text-sm md:text-base"><NumericFormat value={five_year_profit_impact_deep_dive} displayType="text" thousandSeparator prefix={currencyValue} /></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </div>

              {/* Graph Section */}
              <div className="bg-white shadow rounded w-full overflow-y-auto max-h-screen md:max-h-[70vh] sm:max-h-[55vh]">
                {/* <Header title="Graph 3" /> */}
                <div className="p-4">
                  {/* Graph 3 Content */}
                  <Valuation starting_valuation={starting_valuation} potential_valuation={potential_valuation} currency={currencyValue} />
                </div>
              </div>

              {/* Text and Video Section */}
              <div className="flex flex-col md:flex-col lg:flex-col xl:flex-row space-y-4 md:space-y-4 lg:space-y-4 xl:space-y-0 md:space-x-0 xl:space-x-4 lg:space-x-0">
                <div className="flex-1 bg-white shadow rounded w-full">
                  {/* <Header title="Text Summary" /> */}
                  <div className="bg-white shadow rounded w-full h-full min-w-3xl mx-auto p-6 overflow-y-auto overflow-x-auto max-h-screen sm:max-h-full">
                    <section className="mb-6">
                      <div className="flex items-center mb-4">
                        <img src={Yellow_Triangle} alt="Yellow Arrow" className="w-5 h-7 mr-3" />
                        <span className="text-yellow-600 font-semibold text-base sm:text-lg md:text-lg">Friendly Advice: Demand a positive ROI.</span>
                      </div>
                      <p className="text-gray-700 text-base mb-4">
                        <b>We recommend that you don't work with any coach, consultant, or advisor that doesn't guarantee you a positive Return On Investment (ROI)!</b>
                      </p>
                      <p className="text-gray-700 text-base mb-4">
                        A "positive ROI" simply means that you make more money working with your coach, consultant, or advisor than you invested with them. Why would you consider anything less?
                      </p>
                      <p className="text-gray-700 text-base mb-4">
                        It makes no sense to spend more money to obtain a certain result than you earn. That's called a "negative ROI" ... and is a drain on your profitability.
                      </p>
                      <p className="text-gray-700 text-base">
                        But this is also a 2-way street. <b>Here’s <i>your</i> responsibility.</b> You have to implement to the level that we mutually agree upon. We may have a winning strategy that could double your business but it’s worthless if you fail to properly implement it.
                        In this simulator experience, we’ll give you strategies that are proven and time-tested. <b>Implement them consistently and you will experience true Profit Acceleration.</b>
                      </p>
                    </section>
                  </div>

                </div>
                <div className="flex-1 bg-white shadow rounded w-full">
                  {/* <Header title="Video" /> */}
                  <div className="p-4">
                    {/* Video Content */}
                    <VideoTwo
                      image={VideoImageTwo}
                      video={video_2}
                    />
                  </div>
                </div>
              </div>

              {/* Button Section */}
              <div className="flex justify-center mt-4">
                <Button className="bottom-button-two m-auto lg:!w-1/2 sm:!w-3/4 shadow" onClick={modalToggle}>
                  Click here to get your personalized 12-month road map
                </Button>
              </div>

              {/* Social Media Icons Section */}
              <div className="flex justify-center space-x-4 mt-4">
                <PinterestShareButton
                  url={share_url}
                  media={share_url}
                  className="share-images"
                >
                  <PinterestIcon className="share-image-icon" round />
                </PinterestShareButton>
                <TwitterShareButton
                  url={share_url}
                  className="share-images"
                >
                  <TwitterIcon className="share-image-icon" round />
                </TwitterShareButton>
                <FacebookShareButton
                  url={share_url}
                  className="share-images"
                >
                  <FacebookIcon className="share-image-icon" round />
                </FacebookShareButton>
                <EmailShareButton
                  url={share_url}
                  className="share-images"
                >
                  <EmailIcon className="share-image-icon" round />
                </EmailShareButton>
                <TumblrShareButton
                  url={share_url}
                  posttype="link"
                  className="share-images"
                >
                  <TumblrIcon className="share-image-icon" round />
                </TumblrShareButton>
                <WhatsappShareButton
                  url={share_url}
                  className="share-images"
                >
                  <WhatsappIcon className="share-image-icon" round />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={share_url}
                  className="share-images"
                >
                  <LinkedinIcon className="share-image-icon" round />
                </LinkedinShareButton>
              </div>
            </div>
          )}

          {/* Popup */}

          <Modal isOpen={modal} toggle={modalToggle} className="modal-lg modal-fullscreen-sm-down">
            <ModalHeader toggle={modalToggle}>Please enter your information below:</ModalHeader>

            {isLoading ? (
              <ModalBody>
                <div className="flex flex-col items-center justify-center text-lg">
                  Processing your report. Please wait.
                  <Spinner color="success" size="lg" className="mt-8" />
                </div>
              </ModalBody>
            ) : submissionSuccess ? (
              <>
                <ModalBody>
                  <p>An email has been sent to you with the link to the report</p>
                </ModalBody>
                <ModalFooter>
                  <Button className="modal-cancel-btn" onClick={modalToggle}>Close</Button>
                </ModalFooter>
              </>
            ) : (
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                }}
                validationSchema={userValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSaveData(values);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                    <Form>
                      <ModalBody>
                        <FormGroup>
                          <Field
                            type="text"
                            name="first_name"
                            className="my-3 form-control"
                            placeholder="First Name"
                          />
                          <ErrorMessage name="first_name" component="span" className="text-red-600 text-sm" />
                        </FormGroup>
  
                        <FormGroup>
                          <Field
                            type="text"
                            name="last_name"
                            className="my-3 form-control"
                            placeholder="Last Name"
                          />
                          <ErrorMessage name="last_name" component="span" className="text-red-600 text-sm" />
                        </FormGroup>
  
                        <FormGroup>
                          <Field
                            type="email"
                            name="email"
                            className="my-3 form-control"
                            placeholder="Email"
                          />
                          <ErrorMessage name="email" component="span" className="text-red-600 text-sm" />
                        </FormGroup>
                      </ModalBody>
  
                      <ModalFooter>
                        <Button type="submit" disabled={isSubmitting} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                          Save
                        </Button>
                        <Button className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700" onClick={modalToggle}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Form>
                  )}
              </Formik>
            )}
          </Modal>
        </main>
      </Transition>
    </div>
  );
}

export default Public;