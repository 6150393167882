import React, { useEffect, useState } from 'react';
import { Impact } from '../../../components/impact';
import { ImpactTwo } from '../../../components/impact_two';
import './style.scss';

function GraphTwo(props) {
    const { data, currency } = props;
    const [dimensions, setDimensions] = useState({ height: '55vh', width: 500 });
    const [useImpactTwo, setUseImpactTwo] = useState(window.innerWidth < 1280);
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    const style = 'notblured';

    const handleResize = () => {
        const screenWidth = window.innerWidth;
        let height;
        let width;

        if (screenWidth >= 2560) {
            // Extra large screens
            height = 1400;
            width = 1680;
        } else if (screenWidth >= 1920) {
            // Larger screens
            height = 900;
            width = 800;
        } else if (screenWidth >= 1440) {
            // Large screens
            height = 800;
            width = 600;
        } else if (screenWidth >= 1280) {
            // Medium-large screens
            height = 750;
            width = 500;
        } else if (screenWidth >= 1024) {
            // Medium screens
            height = '65vh';
            width = 450;
        } else if (screenWidth >= 768) {
            // Small-medium screens
            height = 600;
            width = 300;
        } else if (screenWidth >= 640) {
            // Small screens
            height = '55vh';
            width = 350;
        } else {
            // Extra small screens
            height = '50vh';
            width = 300;
        }

        setDimensions({ height, width });
        setUseImpactTwo(screenWidth < 1280);
    };

    useEffect(() => {
        handleResize(); // Call initially to set the correct dimensions
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const checkDataEmpty = () => {
            if (Array.isArray(data)) {
                return data.every(item => item.current_revenue === 0);
            }
            if (typeof data === 'object' && data !== null) {
                return data.current_revenue === 0;
            }
            return true;
        };

        setIsDataEmpty(checkDataEmpty());
    }, [data]);

    const title = 'Your Projected Revenue and Profits';
    const symbol = currency;
    const graph_type = 'two';

    return (
        <section style={{ width: '100%' }}>
            <div className="bg-white">
                <div className="bg-[#14153e] text-white text-xs sm:text-sm md:text-base text-center py-2 uppercase">
                    {title}
                </div>
                <div className="p-4 flex items-center">
                    <section className={`h-full flex items-center justify-center ${style}`}>
                        {isDataEmpty ? (
                            <div className="text-center font-bold text-[#009247] text-lg sm:text-xl md:text-2xl mt-[42%]">
                                Please enter your company's revenue, gross, and net profit margins.
                            </div>
                        ) : (
                            <Impact symbol={symbol} summary={data} width={dimensions.width} graph_type={graph_type} />
                        )}
                    </section>
                </div>
            </div>
        </section>
    );
}

export default GraphTwo;
