import { memo } from 'react';
import Player from './player';
import VideoImageOne from '../images/Simulator-Video-Image-HD.jpg';

function VideoOne(props) {
    const { image, video } = props;
    const height = '100%';
    let img;

    if (screen.width === 1280) {
        img = VideoImageOne;
    } else {
        img = image;
    }

    return (
        <section className="w-full h-full">
            <Player
                medium
                image={img}
                video={video}
                // height={height}
                className="w-full h-full"
            />
        </section>
    );
}

export default memo(VideoOne);