import React, { useEffect, useState } from 'react';
import { ValuationChart } from '../../components/valuation';
import { GraphWrapper } from './styles';

function Valuation(props) {
    const { starting_valuation, potential_valuation, currency } = props;
    const symbol = currency;
    const [isDataEmpty, setIsDataEmpty] = useState(true);

    useEffect(() => {
        const checkDataEmpty = () => (starting_valuation === 0 || potential_valuation === 0 || starting_valuation === undefined || potential_valuation === undefined);

        setIsDataEmpty(checkDataEmpty());
    }, [starting_valuation, potential_valuation]);

    return (
        <GraphWrapper className="flex items-center justify-center">
            <div className="bg-white shadow-lg rounded-lg w-full">
                <div className="bg-[#14153e] text-white text-xs sm:text-sm md:text-base text-center py-2 uppercase">
                    Business Valuation
                </div>
                <div className="h-full flex items-center p-4 overflow-y-auto max-h-screen md:max-h-[70vh] sm:max-h-[55vh]">
                    <section className="notblured business-valuation h-full flex items-center justify-center">
                        {isDataEmpty ? (
                            <div className="text-center font-bold text-[#009247] text-lg sm:text-xl md:text-2xl">
                                Please enter your company's revenue, gross, and net profit margins.
                            </div>
                        ) : (
                            <ValuationChart symbol={symbol} starting={starting_valuation} potential={potential_valuation} />
                        )}
                    </section>
                </div>
            </div>
        </GraphWrapper>
    );
}

export default Valuation;
