import styled from 'styled-components/macro';
import ReactPlayer from 'react-player/lazy';

export const PlayerSimOne = styled(ReactPlayer)`
    width: 100% !important;
    
    position: relative;
    height: 0; /* Control height using padding-top for aspect ratio */
    background-color: black; /* Optional: for better visibility before load */
    & > .react-player__preview{
      width: 100%;
      height: 100%;
      background-size: 50rem 100% !important;
      position: absolute;
      top: 0;
      left: 0;
    }
    & > .react-player__preview > video{
      width: 100% !important;
      height: 100% !important;
    }

    @media screen and (max-width: 767px) and (orientation: portrait) {
      height: 18rem !important;
      & > .react-player__preview {
        background-size: 77.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
      height: 32rem !important;
      & > .react-player__preview {
        background-size: 87.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 1024px) and (max-width: 1279px) and (orientation: portrait) {
      height: 83rem !important;
      & > .react-player__preview {
        background-size: 46.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 1280px) and (orientation: portrait) {
      height: 58rem !important;
      & > .react-player__preview {
        background-size: 43.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 1281px) and (max-width: 1523px) and (orientation: portrait) {
      height: 47.75rem !important;
      & > .react-player__preview {
        background-size: 42rem 100% !important;
      }
    }
    
    @media screen and (min-width: 1524px) and (max-width: 1858px) and (orientation: portrait) {
      height: 48.15rem !important;
      & > .react-player__preview {
        background-size: 47rem 100% !important;
      }
    }
    
    @media screen and (min-width: 1859px) and (max-width: 2560px) and (orientation: portrait) {
      height: 45rem !important;
      & > .react-player__preview {
        background-size: 55rem 100% !important;
      }
    }

    


    @media screen and (max-width: 767px) and (orientation: landscape) {
      height: 18rem !important;
      & > .react-player__preview {
        background-size: 77.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
      height: 25rem !important;
      & > .react-player__preview {
        background-size: 89.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {
      height: 72rem !important;
      & > .react-player__preview {
        background-size: 46.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 1280px) and (orientation: landscape) {
      height: 67rem !important;
      & > .react-player__preview {
        background-size: 48.5vw 100% !important;
      }
    }

    @media screen and (min-width: 1280px) and (max-height: 1024px) and (orientation: landscape) {
      height: 47rem;
      & > .react-player__preview {
        background-size: 48.5vw 100% !important;
      }
    }

    @media screen and (min-width: 1280px) and (max-height: 800px) and (orientation: landscape) {
      height: 67rem !important;
      & > .react-player__preview {
        background-size: 48.5vw 100% !important;
      }
    }
    
    @media screen and (min-width: 1281px) and (max-width: 1523px) and (orientation: landscape) {
      height: 58rem !important;
      & > .react-player__preview {
        background-size: 42rem 100% !important;
      }
    }
    
    @media screen and (min-width: 1524px) and (max-width: 1858px) and (orientation: landscape) {
      height: 55rem !important;
      & > .react-player__preview {
        background-size: 47rem 100% !important;
      }
    }

    @media screen and (min-width: 1859px) and (max-width: 2560px) and (max-height: 1080px) and (orientation: landscape) {
      height: 51.5rem !important;
      & > .react-player__preview {
        background-size: 61rem 100% !important;
      }
    }

    @media screen and (min-width: 1859px) and (max-width: 2560px) and (min-height: 1081px) and (max-height: 1440px) and (orientation: landscape) {
      height: 48.5rem !important;
      & > .react-player__preview {
        background-size: 75rem 100% !important;
      }
    }
    
    
    
`;