import React from 'react';
import { Alert } from 'reactstrap';

import errorImage from './error.jpg';

function ErrorCustomMessage({ message }) {

  return (
    <div className="container-fluid page">
      <section className="main-section">
        <section className="main-content">
          <section className="content-section">
            <section className="left">
              <div className="card" style={{ minHeight: '90vh' }}>
                <div className="card-header" style={{ fontSize: 'xx-large' }}>ERROR</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 npf" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <img className="error-image" src={errorImage} style={{ width: '60%' }} alt="error img" />
                     
                      <div className="col-12 npf">
                        <Alert color="danger" style={{ fontSize: 'x-large' }}>
                          <span><b>Please Note:</b></span> {message} An error while trying to displaying this Simulator. Please Contact <a href="mailto:passupport@focused.com">passupport@focused.com</a>
                        </Alert>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}

export default ErrorCustomMessage;

