import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { PlayerSimOne } from './styled';

function Player({ video, handleProgress, image, small, medium, handleDuration, height }) {
    const [playbackRate] = useState(1.0);


    const classStyle = 'react-player-one';
    const style = (small) ? 'player-wrapper-one small' : ((medium) ? 'player-wrapper-one medium' : 'player-wrapper-one large');
    

    return (
        <div className={style}>
            <PlayerSimOne
                controls
                className={classStyle}
                playing
                onDuration={handleDuration}
                onProgress={handleProgress}
                pip
                playbackRate={playbackRate}
                width="100%"
                height={height}
                light={image}
                url={video}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload',
                        },
                    },
                }}
            />
        </div>
    );
}

export default memo(Player);

Player.propTypes = {
    video: PropTypes.string.isRequired,
    image: PropTypes.string,
};
