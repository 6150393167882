import numeral from 'numeral';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';

const getChartOptions = (props, isVertical) => ({
  chart: {
    type: isVertical ? 'column' : 'bar',
    height: isVertical ? 300 : 130,
    width: null,
    style: {
      fontFamily: 'Open Sans',
    },
    options3d: {
      enabled: true,
      alpha: 15,
      beta: 0.5,
      depth: 50,
      viewDistance: 100,
      frame: {
        bottom: {
          size: 1,
          color: 'rgba(0,0,0,0.05)',
        },
      },
    },
  },
  title: {
    text: null,
  },
  xAxis: {
    type: 'category',
    categories: ['Starting Valuation', 'Potential Valuation'],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: null,
      align: 'high',
    },
    labels: {
      overflow: 'justify',
    },
  },
  exporting: { enabled: false },
  tooltip: {
    valueSuffix: ' thousands',
    pointFormat: 'Value: {point.y:,.0f} mm',
    formatter() {
      const y = numeral(this.y).format('0,0');
      return `${this.x} is <b>${props.symbol}${y}</b>`;
    },
  },
  plotOptions: {
    series: {
      pointPadding: 0,
      groupPadding: 0.1,
    },
    [isVertical ? 'column' : 'bar']: {
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: 'normal',
          textOutline: 'none',
          color: '#fff',
        },
        formatter() {
          const y = numeral(this.y).format('0,0');
          return `${props.symbol}${y}`;
        },
      },
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  series: [{
    dataLabels: [{
      align: isVertical ? 'center' : 'right',
    }],
    data: [{
      y: props.starting,
      name: 'Starting Valuation',
      color: '#4499d7',
    }, {
      y: props.potential,
      name: 'Potential Valuation',
      color: '#408f4d',
    }],
  }],
});

export function ValuationChart(props) {
  const [isVertical, setIsVertical] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getChartOptions(props, isVertical)}
    />
  );
}
