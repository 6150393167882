import { memo } from 'react';
import Player from './player';


function VideoTwo(props) {
    const { image, video } = props;
    const height = '100%';
    let img;

    // if (screen.width === 1280) { // smaller image
    //     img = VideoImageOne;
    // } else {
    //     img = image;
    // }
    return (
        <section className="w-full h-full">
            <Player
                medium
                image={image}
                video={video}
                height={height}
                className="w-full h-full"
            />
        </section>
    );
}

export default memo(VideoTwo);