import styled from 'styled-components/macro';
import ReactPlayer from 'react-player/lazy';

export const PlayerSimTwo = styled(ReactPlayer)`
    width: 100% !important;
    height: 100% !important;
    & > .react-player__preview{
      width: 100%;
      height: 100%;
      background-size: 20rem 100% !important;
      position: absolute;
      top: 0;
      left: 0;
    }

    & > .react-player__preview > video{
      width: 100% !important;
      height: 100% !important;
    }
    @media screen and (max-width: 767px) {
      height: 12rem !important;
      & > .react-player__preview{
          background-size: 67.5vw 100% !important;
      }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1279px) {
      height: 12rem !important;
      & > .react-player__preview{
          background-size: 81.5vw 100% !important;
      }
  }

    @media screen and (min-width: 1280px) {
      height: 34rem !important;
      & > .react-player__preview{
        background-size: 49vw 100% !important;
      }
    }

    @media screen and (min-width: 1280px) and (max-height: 1024px) {
      height: 37rem !important;
      & > .react-player__preview{
        background-size: 49vw 100% !important;
      }
    }

    @media screen and (min-width: 1281px) and (max-width: 1523px) {
      height: 32rem !important;
      & > .react-player__preview{
        background-size: 41rem 100% !important;
      }
      
    }
    @media screen and (min-width: 1524px) and (max-width: 1858px) {
      height: 35.15rem !important;
      & > .react-player__preview{
        background-size: 46rem 100% !important;
      }
      
    }
   
    @media screen and (min-width: 1859px) and (max-width: 2560px) {
      height: 27rem !important;
      & > .react-player__preview{
        background-size: 73rem 100% !important;
      }
    }
`;