import numeral from 'numeral';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const options = (props) => {
  const { summary, symbol, graph_type } = props;

  const currentProfit = summary.current_profit;
  const currentRevenue = summary.current_revenue;
  const startingValuation = summary.current_valuation;

  const newAnnualProfit = summary.expected_profit_increase;
  const newAnnualGrossRevenue = summary.expected_revenue_increase;
  const potentialValuation = summary.expected_valuation_increase;

  let categories;
  let expected_increase;
  let current;
  if (graph_type === 'one') {
    categories = ['Profit', 'Revenue', 'Valuation'];
    current = [currentProfit, currentRevenue, startingValuation];
    expected_increase = [newAnnualProfit, newAnnualGrossRevenue, potentialValuation];
  } else {
    categories = ['Profit', 'Revenue'];
    current = [currentProfit, currentRevenue];
    expected_increase = [newAnnualProfit, newAnnualGrossRevenue];
  }


  return {
    chart: {
      type: 'column',
      renderTo: 'impact-chart',
      style: {
        fontFamily: 'Open Sans',
      },
      options3d: {
        enabled: true,
        alpha: 23,
        beta: 14,
        depth: 100,
        viewDistance: 25,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
      },
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: {
      categories,
      crosshair: true,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      crosshair: true,
      labels: {
        overflow: 'justify',
      },
      title: {
        text: null,
      },
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 100,
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
          },
        },
      }],
    },
    exporting: { enabled: false },
    tooltip: {
      valueSuffix: ' thousands',
      formatter() {
        const y = numeral(this.y).format('0,0');
        return `${this.series.name} ${this.x}: <b> ${symbol}${y}</b>`;
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0.05,
        borderWidth: 0,
        maxPointWidth: 60,
        shadow: false,
      },
    },
    credits: {
      enabled: false,
    },
    series: [{
      name: 'Current',
      data: current,
      color: '#4499d7',
    }, {
      name: 'Expected',
      data: expected_increase,
      color: '#408f4d',
    }],
  };
};

export function ImpactTwo(props) {
  return (
<HighchartsReact
    highcharts={Highcharts}
    options={options(props)}
/>
);
}