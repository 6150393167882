import styled, { keyframes, css } from 'styled-components';
import { Button, CardText, Alert, Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { mix, darken } from 'polished';
import Select from 'react-select';


const clipBoardAnimation = keyframes`
  0% { transform: translateY(-50%) scale(1); }
  50% { transform: translateY(-50%) scale(1.2); }
  100% { transform: translateY(-50%) scale(1); }
`;

export const SettingsContainer = styled.section`
  width: 100%;
`;

export const SimContainer = styled.section`
  width: 100%;
  min-height: 50vh;
`;


export const URLInput = styled.input`
  background-color: #e9ecef;
  border: none;
  display: inline-block;
  width: auto;
  min-width: 90%;
  max-width: 100%;
  font-size: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.2rem;
`;

export const ShareButton = styled(Button)`
  margin-bottom: 1rem;
`;

export const URLText = styled(CardText)`
    font-size: 1.75rem;  
`;
export const BreadcrumbContainer = styled.section`
.breadcrumb{
   background-color: #fff;
   background-clip: border-box;
   border: 1px solid #e9ecef;
   border-radius: .2rem;

}
`;

export const TableLink = styled(NavLink).attrs(() => ({
  className: 'nav-link',
}))`
    padding: 0.2rem 0.5rem;
    color: ${props => ((props.paused === 'true') ? '#169bd6' : 'black')};
    font-size: 0.75rem;
    line-height: 1;
    & > i{
      font-size: 0.8rem !important;
      color: #212529;
      transition: 300ms ease all;
    }
  `;

export const NoSims = styled.h6`
    text-transform: uppercase;
    margin-bottom: 2%;
    font-size: 0.8rem;
    color: #179ad6;
    text-align:center;
    padding-bottom: 3px;
    font-weight: 600;
`;

export const ShareContainer = styled.div`
    margin: 10px 20px 0px 0px;
    padding: 10px 20px 0px 0px;
`;

export const Actions = styled.span`
  display: flex;
  width: 100%;
`;

export const LNavLink = styled(NavLink).attrs(() => ({
  className: 'btn btn-primary btn-sm',
}))`
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1;
    &:focus, &:active {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &:hover{
      border: none;
      box-shadow: none;
      outline: none;
      background-color: #212529 !important;
      border: 1px solid #212529 !important;
      color: #fefefe;
    }
    &:hover > i{
      color: #fefefe !important;
    }
    & > i{
      font-size: 0.8rem !important;
      color: #212529;
      transition: 300ms ease all;
    }
  `;

export const AlertBox = styled(Alert)`
    font-size: 1rem;  
`;
export const URLInputContainer = styled.div`
    position: relative;
    width: auto; 
`;

export const ClipboardIcon = styled.i`
  margin: 0 0 0 1.5% !important;
  display: inline;
  cursor: pointer;
  font-size: 1.5rem;
  transform: translateY(-50%);
  color: ${(props) => (props.isClicked ? '#008a00' : 'inherit')}
  &:hover {
    color: #007bff;  
  }
  animation: ${(props) => (props.isClicked ? css`${clipBoardAnimation} 3s ease-in-out` : 'none')};
`;

export const GraphWrapper = styled.section`
  margin-bottom: 0.75rem;
  & > .card .card-header {
      font-size: 0.9rem !important;
      padding: 1.4% !important;
      font-weight: 600 !important;
  }
  & > .valuation-card .card-header {
      font-size: 0.9rem !important;
      padding: 1% !important;
      font-weight: 600 !important;
  }
`;

export const SimButton = styled(Button)`
  background: #fff;
  color: #fff;
  border: none;
  margin: ${props => props.margin || '0 5px'};
  width: ${props => props.width || 'auto'};
  padding: 3px 10px;    
  border-radius: 2px;
  letter-spacing: 0.06em;
  text-decoration: none;
  outline: none;
  font-size: 0.7rem;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  float: ${props => props.float || 'none'};
  &:disabled {
    background: #eee !important;
    color: #868686 !important;
  }
  &:disabled&:hover {
    background-color: ${mix(0.85, '#868686', '#fff')} !important;
    color: #fefefe !important;
  }
  &:hover {
    color: #fff !important;
  }
  &:focus {
    border-radius: 0;
  }
  &.btn-submit {
    background: #179ad6;
    text-transform: uppercase;
    color: ${mix(0.7, '#fefefe', '#fff')} !important;
    &:hover {
      background: ${darken(0.5, '#179ad6')} !important;
      color: ${mix(0.85, '#fefefe', '#fff')} !important;
    }
    &:focus {
      box-shadow: none;
    }
    & > svg{
      height: auto;
      fill: #fefefe;
    }
  }
  &.btn-save {
    background: #408f4d !important;
    width: 20%;
    color: ${mix(0.7, '#fefefe', '#fff')} !important;
    &:hover {
      background: ${darken(0.2, '#408f4d')} !important;
      color: ${mix(0.85, '#fefefe', '#fff')} !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
  &.btn-cancel {
    background: #eee !important;
    border: 1px solid #868686 !important;
    color: #868686 !important;
    &:hover {
      background: ${darken(0.5, '#eee')} !important;
      color: #fff !important;
    }
    &:focus {
      box-shadow: none;
    }
  }

`;

export const FirstVideoContainer = styled.section`
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 30.25rem;

@media screen and (max-width: 1280px) {
  height: 41.5rem !important;
}
@media screen and (min-width: 1281px) and (max-width: 1523px) {
  height: 37.5rem !important;
}
@media screen and (min-width: 1524px) and (max-width: 2560px) {
  height: 36rem !important;
}
`;

export const Section = styled.section`
display: flex;
justify-content: space-between; // For left and right alignment
@media (max-width: 768px) {
    flex-direction: column;
    text-align: center; // Center text for smaller screens
}
`;

export const TextContent = styled.span`
line-height: 1.6;
margin: 0 20px; // Add some margin for better spacing

@media (max-width: 768px) {
    max-width: 100%;
    margin: 20px 0; // Adjust margin for smaller screens
}
`;

export const Paragraph = styled.p`

@media (max-width: 768px) {
    font-size: 14px !important; // Slightly smaller font size for mobile
}
`;

export const ImageContainer = styled.img`
max-width: 100%;
height: inherit !important;
margin: 3px;

@media (max-width: 768px) {
    width: 80%; // Reduce image size on smaller screens
    margin: 4px 4px 10px 0; // Add margin below the image
}
`;
export const FormInputContainer = styled.section`
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: flex-start;
    height: 3.125rem;
    background-color: #f4f4f6;
    justify-content: space-evenly;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
`;

export const InputSection = styled.section`
    margin-bottom: 20px;
`;

export const FormLabelContainer = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SimLabel = styled.span`
    font-size: 16px;
`;
export const SimLabelSelect = styled.span`
    font-size: 16px;
    margin-bottom: 3.5%;
    @media screen and (min-width: 1180px) and (max-width: 1280px) {
      margin-bottom: 19% !important;
    }
`;

export const SimInput = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 0px;
`;

export const SimSelect = styled(Select)`
    width: 100%;
    border-radius: 0px;
    font-size: 11px;
    .react-select__control {
      border-color: #6b7280 !important;
      border-radius: 0 !important;
      height: 42px !important;
      font-size: 1rem !important;
    }
`;

export const ImpactTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0.75rem;
`;

export const CalcInputContainer = styled(Container)`
    padding: 2rem 1rem 0rem 1rem;

    @media screen and (max-width: 1280px) {
      padding-bottom: 4rem;
    }

    @media screen and (min-width: 1281px) and (max-width: 1403px) {
      padding: 2rem 1rem 0rem 1rem;
    }
    @media screen and (min-width: 1404px) and (max-width: 1418px) {
      padding: 2rem 2rem 0rem 2rem;
    }
    @media screen and (min-width: 1859px) and (max-width: 2560px) {
      padding: 2rem 3rem 0rem 3rem;
    }
`;

export const HeaderBlock = styled.th.attrs({
  colspan: 4,
})`
  height: 10px;
  background-color: #fabf08;
`;

