import React, { useEffect, useState } from 'react';
import { Impact } from '../../../components/impact';
import { ImpactTwo } from '../../../components/impact_two';

function GraphOne(props) {
    const { data, currency } = props;
    const [dimensions, setDimensions] = useState({ height: 530, width: 500 });
    const [useImpactTwo, setUseImpactTwo] = useState(window.innerWidth < 1280);
    const [isDataEmpty, setIsDataEmpty] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth === 1280) {
                setDimensions({ height: 450, width: 450 });
            } else {
                setDimensions({ height: 530, width: 500 });
            }
            setUseImpactTwo(window.innerWidth < 1280);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const title = 'Your Projected Revenue and Profits';
    const symbol = currency;
    const graph_type = 'one';

    // Check if any value in the data is zero or empty
    useEffect(() => {
        const checkDataEmpty = () => {
          if (Array.isArray(data)) {
            return data.every(item => item.current_revenue === 0);
          } 
          if (typeof data === 'object' && data !== null) {
            return data.current_revenue === 0;
          }
          return true;
        };
    
        setIsDataEmpty(checkDataEmpty());
      }, [data]);

    return (
        <section className="h-full">
            <div className="graph-one-card bg-white h-full">
                <div className="bg-[#14153e] text-white text-xs sm:text-sm md:text-base text-center py-2 uppercase">
                    {title}
                </div>
                <div className="card-body graph-one-card-body p-4 h-full flex items-center">
                    <section className="h-full flex items-center">
                        {isDataEmpty ? (
                            <div className="text-center font-bold text-[#009247] text-lg sm:text-xl md:text-2xl mt-[42%]">
                                Please enter your company's revenue, gross, and net profit margins.
                            </div>
                        ) : (
                            useImpactTwo ? (
                                <ImpactTwo symbol={symbol} summary={data} height={dimensions.height} width={dimensions.width} graph_type={graph_type} />
                            ) : (
                                <Impact symbol={symbol} summary={data} height={dimensions.height} width={dimensions.width} graph_type={graph_type} />
                            )
                        )}
                    </section>
                </div>
            </div>
        </section>
    );
}

export default GraphOne;
