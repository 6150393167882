import React from 'react';
import { Code, List, BulletList } from 'react-content-loader';

function LoadingScreen() {
  return (
<div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '80%',
    zIndex: 9999,
  }}
>
    <Code 
      speed={2}
      width="50%"
      height="50%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    />
     <List 
      speed={2}
      width="50%"
      height="50%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
     />
    <List 
      speed={2}
      width="50%"
      height="50%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    />
    <BulletList 
      speed={2}
      width="50%"
      height="50%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    />
</div>
);
}

export default LoadingScreen;
