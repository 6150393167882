export const getCurrencies = [
    {
        id: 1,
        name: 'US dollar ',
        code: '$ ',
        symbol: 'USD',
    },
    {
        id: 2,
        name: 'Canadian dollars ',
        code: 'CA$',
        symbol: 'CAD',
    },
    {
        id: 3,
        name: 'Australian dollars ',
        code: 'AU$',
        symbol: 'AUD',
    },
    {
        id: 4,
        name: 'Pounds sterling ',
        code: '£ ',
        symbol: 'GBP',
    },
    {
        id: 5,
        name: 'Euro ',
        code: '€ ',
        symbol: 'EUR',
    },
    {
        id: 6,
        name: 'Bangladeshi taka ',
        code: '৳ ',
        symbol: 'BDT',
    },
    {
        id: 7,
        name: 'Brazilian real ',
        code: 'R$ ',
        symbol: 'BRL',
    },
    {
        id: 8,
        name: 'Bulgarian lev ',
        code: 'лв',
        symbol: 'BGN',
    },
    {
        id: 10,
        name: 'Chilean peso ',
        code: 'CL$ ',
        symbol: 'CLP',
    },
    {
        id: 11,
        name: 'Chinese yuan ',
        code: '¥',
        symbol: 'CNY',
    },
    {
        id: 12,
        name: 'Colombian peso ',
        code: 'CO$ ',
        symbol: 'COP',
    },
    {
        id: 13,
        name: 'Croatian Kuna ',
        code: 'kn ',
        symbol: 'HRK',
    },
    {
        id: 14,
        name: 'Czech koruna ',
        code: 'Kč ',
        symbol: 'CZK',
    },
    {
        id: 15,
        name: 'Danish krone ',
        code: 'kr ',
        symbol: 'DKK',
    },
    {
        id: 16,
        name: 'Emirati dirham ',
        code: 'د.إ',
        symbol: 'AED',
    },
    {
        id: 17,
        name: 'Georgian lari ',
        code: '₾',
        symbol: 'GEL',
    },
    {
        id: 18,
        name: 'Hong Kong dollar ',
        code: 'HK$',
        symbol: 'HKD',
    },
    {
        id: 19,
        name: 'Hungarian forint ',
        code: 'ft ',
        symbol: 'HUF',
    },
    {
        id: 20,
        name: 'Indian rupee ',
        code: '₹ ',
        symbol: 'INR',
    },
    {
        id: 21,
        name: 'Indonesian rupiah ',
        code: 'Rp ',
        symbol: 'IDR',
    },
    {
        id: 22,
        name: 'Israeli shekel ',
        code: '₪ ',
        symbol: 'ILS',
    },
    {
        id: 23,
        name: 'Japanese yen ',
        code: '¥ ',
        symbol: 'JPY',
    },
    {
        id: 24,
        name: 'Kenyan shilling ',
        code: 'Ksh ',
        symbol: 'KES',
    },
    {
        id: 25,
        name: 'Malaysian ringgit ',
        code: 'RM ',
        symbol: 'MYR',
    },
    {
        id: 26,
        name: 'Mexican peso ',
        code: 'MX$',
        symbol: 'MXN',
    },
    {
        id: 27,
        name: 'Moroccan dirham ',
        code: '.د.م ',
        symbol: 'MAD',
    },
    {
        id: 28,
        name: 'New Zealand dollar ',
        code: 'NZ$',
        symbol: 'NZD ',
    },
    {
        id: 29,
        name: 'Nigerian naira ',
        code: '₦ ',
        symbol: 'NGN',
    },
    {
        id: 30,
        name: 'Norwegian krone ',
        code: 'kr ',
        symbol: 'NOK',
    },
    {
        id: 31,
        name: 'Pakistani rupee ',
        code: 'Rs ',
        symbol: 'PKR',
    },
    {
        id: 32,
        name: 'Peruvian sol ',
        code: 'S/. ',
        symbol: 'PEN',
    },
    {
        id: 33,
        name: 'Philippine peso ',
        code: '₱ ',
        symbol: 'PHP',
    },
    {
        id: 34,
        name: 'Polish zloty ',
        code: 'zł ',
        symbol: 'PLN',
    },
    {
        id: 35,
        name: 'Romanian leu ',
        code: 'lei',
        symbol: 'RON',
    },
    {
        id: 36,
        name: 'Russian ruble ',
        code: '₽ ',
        symbol: 'RUB',
    },
    {
        id: 37,
        name: 'Rwandan franc ',
        code: 'FRw ',
        symbol: 'RWF',
    },
    {
        id: 38,
        name: 'Singapore dollar ',
        code: 'S$ ',
        symbol: 'SGD',
    },
    {
        id: 39,
        name: 'South african rand ',
        code: 'R ',
        symbol: 'ZAR',
    },
    {
        id: 40,
        name: 'South Korean won ',
        code: '₩ ',
        symbol: 'KRW',
    },
    {
        id: 41,
        name: 'Sri Lankan rupee ',
        code: 'Rs ',
        symbol: 'LKR',
    },
    {
        id: 42,
        name: 'Swedish krona ',
        code: 'kr ',
        symbol: 'SEK',
    },
    {
        id: 43,
        name: 'Swiss franc ',
        code: 'CHF',
        symbol: 'CHF',
    },
    {
        id: 43,
        name: 'Thai baht ',
        code: '฿ ',
        symbol: 'THB',
    },
    {
        id: 44,
        name: 'Turkish lira ',
        code: '₺ ',
        symbol: 'TRY',
    },
    {
        id: 45,
        name: 'Uganda shilling ',
        code: 'Ush ',
        symbol: 'UGX',
    },
    {
        id: 46,
        name: 'Ukrainian hryvna ',
        code: '₴ ',
        symbol: 'UAH',
    },
    {
        id: 47,
        name: 'Vietnamese dong ',
        code: '₫ ',
        symbol: 'VND',
    },
    {
        id: 48,
        name: 'Zambian kwacha ',
        code: 'ZK ',
        symbol: 'ZMW',
    },
    {
        id: 49,
        name: 'Zimbabwean dollar ',
        code: 'ZWL$',
        symbol: 'ZWL',
    },
];
