import { NumericFormat } from 'react-number-format';

function TableTwo(props) {
    const { data, total_profit_impact, percentage_total_profit_impact, currency } = props;

    const getRowsData = () => {
        const items = data;
        return items.map((row, index) => (
          <tr key={index} className="text-xs sm:text-sm lg:text-base">
            <td className="row-id-data px-2 py-1 sm:px-4 sm:py-2 w-3/12 sm:w-2/12">
              <span>{row.id}</span>
            </td>
            <td className="row-item-data px-2 py-1 sm:px-4 sm:py-2 text-left w-6/12 sm:w-4/12 lg:w-6/12 break-words">
              {row.item}
            </td>
            <td className="row-amount-data px-2 py-1 sm:px-4 sm:py-2 text-center w-3/12 sm:w-5/12 lg:w-4/12">
              <NumericFormat
                value={row.amount}
                displayType="text"
                thousandSeparator
                prefix={currency}
              />
            </td>
          </tr>
        ));
      };
      
    getRowsData();
    return (
        <table className="min-w-full bg-white table-data animate__animated animate__fadeIn">
            <thead>
                <tr>
                    <th className="px-4 py-2 w-2/12 text-xs sm:text-sm md:text-base font-medium uppercase tracking-wider"> </th>
                    <th className="text-xs sm:text-sm md:text-base font-medium uppercase tracking-wider px-4 py-2 text-left sm:w-4/12 lg:w-6/12">Item</th>
                    <th className="text-xs sm:text-sm md:text-base font-medium uppercase tracking-wider px-4 py-2 text-center sm:w-5/12 lg:w-4/12">Amount</th>
                </tr>
            </thead>
            <tbody>
                {getRowsData()}
                <tr>
                    <td> </td>
                    <td className="px-0 py-2 md:px-2 md:py-2 text-left text-xs sm:text-sm md:text-base text-wrap font-semibold">Total Profit Impact With a deep dive 40</td>
                    <td className="px-0 py-2 md:px-2 md:py-2 text-center font-semibold text-xs sm:text-sm md:text-base text-wrap">
                        <NumericFormat value={total_profit_impact} displayType="text" thousandSeparator prefix={currency} />
                        <span className="last-row-span"> / </span>{(isNaN(percentage_total_profit_impact)) ? 0 : percentage_total_profit_impact}% +
                    </td>
                </tr>
            </tbody>
        </table>
    );

}
export default TableTwo;